import addUserSource from "@/views/userOperations/IntendedUserEntry/components/addUserSource";
import addUserTips from "@/views/userOperations/IntendedUserEntry/components/addUserTips";
import { SalesShopInfoList } from "@/views/subOrder/api";
import {
  saveShopPotentialUser,
  findList,
  saveInfo,
  saveInfoLabel,
  findListLabel,
  potentialSpart, getDefevtiveProByMatkl
} from "@/views/userOperations/IntendedUserEntry/api";
import { focusonremarkplz } from "@/views/basicInformation/components/newMerchantaddEdit/api";
import { getAdressapi } from "@/views/settlement/api";
import { findFirstMenuItem } from "ant-design-vue/lib/vc-select/util";

export default {
  components: {
    addUserSource,
    addUserTips
  },
  data() {
    return {

      // 面包屑
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/IntentionList",
          name: "/IntentionList",
          title: "用户运营列表"
        },
        {
          path: "",
          name: "",
          title: "意向用户录入"
        }
      ],
      pageLoadFlag: false,// 整个页面的loading
      visible: false,// 新建用户来源弹窗
      visibleTips: false,// 新建用户标签弹窗
      userSorceList: [],
      SorceIds: '',// 选中的用户来源
      userTipsList: [],
      TipsIds: [],// 选中的标签
      materialGroup: [],
      priceGroup: [],
      productList: [
        {
          fetching: false,
          materialGroupId: undefined,
          proInfo: "",
          priceId: undefined,
          dateTime: "",
          proList: []
        }
      ],

      saleShopList: [],// 销量所属门店
      receiveShop: "", // 销售门店姓名
      salesShopInfoId: "", // 销售门店id
      userPhone: "",// 手机号
      userName: "",// 姓名
      sex: 1,// 性别
      addWX: 0,
      shengCode: "",
      shiCode: "",
      quCode: "",
      xianCode: "",
      userAdress: "",// 详细地址
      remark: "",// 备注
      addressHx: [],
      companyList: [],
      fetching: false,
      proList: []

    };
  },
  mounted() {
    if(this.$route.query.type == "list") {
      this.breadcrumbData= [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/IntentionList",
          name: "/IntentionList",
          title: "用户运营列表"
        },
        {
          path: "",
          name: "",
          title: "意向用户录入"
        }
      ]
    } else if(this.$route.query.type == "saleList"){
      this.breadcrumbData= [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/OpportunityList",
          name: "/OpportunityList",
          title: "销售机会"
        },
        {
          path: "",
          name: "",
          title: "新增销售机会"
        }
      ]
    }
    this.getsaleShopList();
    this.getuserSorceList();
    this.getuserTipList();
    this.getBuyList();
    this.getmaterialGrouplist();
  },
  methods: {
    // 获取意向品类
    getmaterialGrouplist() {
      let data = {};
      potentialSpart(data).then(res => {
        if (res.data.code == 0) {
          this.materialGroup = res.data.list;
        } else {
          this.$message.warning(res.data.msg);
        }

      });

    },

    // 获取购买意向接口
    getBuyList() {
      let data = {
        pid: 14927471376
      };
      focusonremarkplz(data).then(res => {
        this.priceGroup = res.data.list;
      });
    },
    // 获取用户标签
    getuserTipList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findListLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList = res.data.data;
          if (this.userTipsList.length > 0) {
            this.userTipsList.forEach(item => {
              item.checked = false;
            });
          }
          this.pageLoadFlag = false;

        }
        this.pageLoadFlag = false;
      });
    },
    // 获取用户来源
    getuserSorceList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findList(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList = res.data.data;
          if(this.userSorceList.length > 0) {
            this.userSorceList.forEach(item => {
              item.id = (item.id).toString();
              item.ischerk = false;
            });
          }

          this.pageLoadFlag = false;
        }
        this.pageLoadFlag = false;

      });
    },
    // 销量所属门店查询
    getsaleShopList() {
      let data = {
        // exclusiveShop: 1
      };
      SalesShopInfoList(data).then(res => {
        this.saleShopList = res.data.list;
      });
    },
    // 修改销售门店
    handleChangeshopList(value, e) {
      this.receiveShop = value;
      this.salesShopInfoId = e.key;
      let obj = this.saleShopList.find((i) => {
        return i.code === e.key;//筛选出匹配数据
      });
      // 回显数据赋值  以及传参数据赋值
      this.addressHx = [obj.provinceCode, obj.cityCode, obj.districtCode, obj.townCode];
      this.userAdress = obj.address;
      this.shengCode = obj.provinceCode ? obj.provinceCode : "";
      this.shiCode = obj.cityCode ? obj.cityCode : "";
      this.quCode = obj.districtCode ? obj.districtCode : "";
      this.xianCode = obj.townCode ? obj.townCode : "";
    },
    // 修改性别
    changSex(e) {
      this.sex = e.target.value;
    },
    // 修改是否添加微信
    changWX(e) {
      this.addWX = e.target.value;
    },
    onAddressChange(addressValArr) {
      // 长度如果等于2 乡镇就置空
      if(addressValArr&&addressValArr.length == 2) {
        this.quCode = '';
      }
      // 长度如果等于3 区县就置空
      if(addressValArr&&addressValArr.length == 3) {
        this.xianCode = '';
      }
      if (addressValArr.length>0) {
        addressValArr.forEach((item, index) => {
          if (index == 0) {
            this.shengCode = item;
          }
          if (index == 1) {
            this.shiCode = item;
          }
          if (index == 2) {
            this.quCode = item;
          }
          if (index == 3) {
            this.xianCode = item;
          }

        });
      } else {
        this.shengCode = ''
        this.shiCode = ''
        this.quCode = ''
        this.xianCode = ''
      }


      // ['340000000000', '340600000000', '340603000000', '340603400000', __ob__: Observer]
      // ..
    },
    //查询数据
    searcha() {

    },
    getAdress(value) {
      let id = null;
      if (this.quCode) {
        id = this.quCode;
      } else if (this.shiCode) {
        id = this.shiCode;
      } else if (this.shengCode) {
        id = this.shengCode;
      } else {
        id = 0;
      }

      this.userAdress = value;
      let a = {
        searchstr: this.userAdress,
        region: id
      };
      let b = {
        searchstr: this.userAdress
      };
      let data = id !== 0 ? a : b;
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList = [];
        }

        this.fetching = false;
      });

    },
    // 获取产品
    getProduct(item, value) {
      let data = {
        term: value
      };
      item.fetching = true;
      getDefevtiveProByMatkl(data).then(res => {
        if (res.data.list.length > 0 && res.data.list !== null) {
          item.proList = [...new Set(res.data.list)]; //缓存列表数据，前端分页
        } else {
          item.proList = [];
        }
        item.fetching = false;
        this.$forceUpdate()
      });
    },
    // 点击
    changeSorceBtn(item) {
      if(this.SorceIds == item.id) {
        this.SorceIds = ''
        return
      }
      this.SorceIds = item.id;
      this.$forceUpdate();
    },
    changeBtnnew(item){
      this.SorceIds = item;
    },

    changeTipsBtn(item) {
      if (!item.checked) {
        if (this.TipsIds.length >= 8) {
          this.$message.warning("用户标签选择不能超过8个");
          return;
        }
      }
      item.checked = !item.checked;
      this.$forceUpdate();
      this.TipsIds = [];
      this.userTipsList.forEach(items => {
        if (items.checked == true) {
          this.TipsIds.push(items.id);
        }
      });
      this.$forceUpdate();
    },
    // 点击弹窗取消和确定的时候
    chengeOK(item) {
      this.TipsIds = [];
      this.TipsIds = item;
      this.userTipsList.forEach(items=>{
        items.checked = false
        item.forEach(itemB=>{
          if(items.id == itemB) {
            items.checked = true
          }
        })
      })
    },
    // 用户来源点击确定 调接口 增加数据 成功的话赋值checked为true
    getReason(value) {
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        source: value,
        remark: ""
      };
      this.pageLoadFlag = true;
      saveInfo(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList.push(
            {
              id: res.data.data,
              source: value
            }
          );
          this.SorceIds = "";
          this.SorceIds = String(this.userSorceList[this.userSorceList.length - 1].id);
          this.$forceUpdate();
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }


      });
    },
    getReasonTip(value, value1) {

      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        label: value,
        remark: value1
      };
      saveInfoLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList.push(
            {
              id: res.data.data,
              label: value,
              checked: true
            }
          );
          this.TipsIds.push(res.data.data);
          this.pageLoadFlag = false;

        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },
    //   更改产品信息价格
    changePrice(e) {

    },
    // 添加产品
    addProItem() {
      this.productList.push({
        fetching: false,
        materialGroupId: undefined,
        proInfo: "",
        priceId: undefined,
        dateTime: "",
        proList: []
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 修改产品信息时间
    changeTime(date, dateString, item) {
      item.dateTime = dateString;
    },
    // 如果删除使用二次确认 将用到此处，如果不用为此确定 需删除
    confirm() {
    },
    // 商品行数据删除
    delectProItem(item, index) {
      if (this.productList.length > 1) {
        this.productList.splice(index, 1);
      } else {
        this.productList.forEach(item => {
          item.materialGroupId = undefined;
          item.proInfo = "";
          item.priceId = undefined;
          item.dateTime = "";
          item.fetching = false,
          item.proList = [];
        });
      }
    },
    // 提交
    sumbit() {
      if (!this.salesShopInfoId) {
        this.$message.warning("请选择销售门店");
        return;
      }
      if(!this.userPhone) {
        this.$message.warning("请输入手机号");
        return;
      }
      if (this.userPhone.trim().length !== 11) {
        this.$message.warning("手机号不正确，请重新填写");
        return;
      }
      if(this.TipsIds.length <= 0) {
        this.$message.warning("请选择用户标签");
        return;
      }
      if(this.SorceIds == '') {
        this.$message.warning('请选择用户来源')
        return false
      }
      let productList = [];
      this.productList.forEach(item => {
        productList.push({
          spartId: item.materialGroupId || "",
          product: item.proInfo,
          budget: item.priceId || "",
          planBuyTime: item.dateTime
        });

      });
      this.pageLoadFlag = true;
      let data = {
        shopInfoId: this.salesShopInfoId,//门店id
        custInfoId: this.$store.state.user.userInfo.customer.id, // 商家id
        userName: this.userName, //意向用户名
        phone: this.userPhone,//电话
        wechat: this.addWX, //微信
        gender: this.sex,//性别
        provinceCode: this.shengCode,//省
        cityCode: this.shiCode,//市
        districtCode: this.quCode,//区
        townCode: this.xianCode,//县
        address: this.userAdress,//详细地址
        headImg: "",//头像
        sourceId: this.SorceIds,//来源id
        remark: this.remark,//备注
        labelList: this.TipsIds,//标签id数组
        userType: 2,//用户类型：海信1、商家2
        dataSource: "pc",
        productList: productList
      };
      saveShopPotentialUser(data).then(res => {
        if (res.data.type == "success") {
          this.$message.success(res.data.text, 5);
          this.pageLoadFlag = false;
          setTimeout(() => {
            if (this.$route.query.type == "list") {
              this.$router.push({ path: "/IntentionList" });
            } else if (this.$route.query.type == "saleList"){
              this.$router.push({ path: "/OpportunityList" });
            }
          }, 2000);
        } else if(res.data.type !== "success" && res.data.code !==400) {
          this.$message.warning("提交失败," + res.data.msg, 5);
          this.pageLoadFlag = false;
        } else {
          this.pageLoadFlag = false;
        }

      });
    },
    goback() {
      if (this.$route.query.type == "list") {
        this.$router.push({ path: "/IntentionList" });
      } else if (this.$route.query.type == "saleList"){
        this.$router.push({ path: "/OpportunityList" });
      }
      else {
        this.$router.push({ path: "/home" });
      }


    },

    // 智能识别
    recognitionFun(res){
      const townCode = res.levelAddress.town_code;
      // 收货地址
      this.addressHx = [townCode.slice(0,2).padEnd(12, 0),townCode.slice(0,4).padEnd(12, 0),townCode.slice(0,6).padEnd(12, 0),townCode.padEnd(12, 0)];
      // 收货地址 code
      this.shengCode = townCode.slice(0,2).padEnd(12, 0);
      this.shiCode = townCode.slice(0,4).padEnd(12, 0);
      this.quCode = townCode.slice(0,6).padEnd(12, 0);
      this.xianCode = townCode.padEnd(12, 0);
      // 详细地址
      // this.userDetailAddress = res.address;
      // 姓名
      this.userName = res.userName;
      // 电话
      this.userPhone = res.mobile;
    }
  }


};
